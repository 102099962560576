.wrapper {
  width: 100%;
  height: 90vh;
  background-color: #0b1e44;
  position: relative;

  @media (min-width:700px) {
    height: 50vh;
  }
  @media (min-width:1200px) {
    height: 80vh;
  }

  .line {
    position: absolute;
    top: 0;
    left: 15%;
    height: 100%;
    width: 3px;
    background-color: rgba(250,250,250,0.2);
  }
  .line2 {
    position: absolute;
    top: 15%;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: rgba(250,250,250,0.2);
  }
}

.logo {
  width: 100%;

  @media (min-width:1200px) {
    padding-top: 1rem;
  }

  &Img {
    display: block;
    margin: 0 auto;
    width: 55%;
    padding: 1rem;

    @media (min-width:700px) {
      width: 35%;
    }
    @media (min-width:1200px) {
      width: 18%;
    }
    @media (min-width:1600px) {
      width: 13%;
    }
  }
}

.leftDiv {
  height: 40vh;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width:700px) {
    padding: 2rem 4rem;
  }
  @media (min-width:1200px) {
    width: 70%;
    margin: 0 auto;
  }


  .headerTitle {
    position: relative;
    font-size: 2.2rem;
    letter-spacing: 2px;
    font-weight: 800;
    color: #fff;
    transform-origin: bottom;
    // text-transform: uppercase;

    &:nth-child(3) .arrow {
      display: inline-block;
      width: 4rem;
      transform: translate(-1.5rem, 3rem);

      @media (min-width:1200px) {
        width: 6rem;
        transform: translate(-1.5rem, 4.5rem);
      }
      @media (min-width:1600px) {
        width: 6.5rem;
        transform: translate(-1.5rem, 4.5rem);
      }
    }

    @media (min-width:700px) {
      font-size: 2.5rem;
    }
    @media (min-width:1200px) {
      font-size: 3rem;
      letter-spacing: 3px;
    }
    @media (min-width:1600px) {
      font-size: 4rem;
      letter-spacing: 3px;
    }
  }
}

.rightDiv {
  position: absolute;
  right: 0;
  bottom: -15%;
  height: 50vh;
  width: 70%;
  transform: scale(1);
  transform-origin: bottom;
  // box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.4);
  background-image: url('../assets/images/ventilation2.jpg');
  background-position: center;
  background-size: cover;
  ;

  @media (min-width: 580px) and (orientation: landscape) {
    height: 55vh;
    width: 40%;
    right: 5%;
    bottom: -20%;
  }
  @media (min-width:700px) {
    height: 45vh;
    width: 45%;
    right: 5%;
    bottom: -30%;
  }
  @media (min-width:1200px) {
    height: 70vh;
    width: 35%;
    right: 10%;
    bottom: -30%;
  }
  @media (min-width:1600px) {
    height: 80vh;
    width: 40%;
    right: 10%;
    bottom: -30%;
  }
}