.wrapper {
  width: 100%;
  padding: 2rem 0;

  @media (min-width:700px) {
    display: grid;
    grid-template-columns: 1fr 55%;
    padding: 0 0 2rem;
  }
  @media (min-width:1200px) {
    padding: 2rem 3rem;
    flex-direction: column;
  }
  @media (min-width:1600px) {
    padding: 2rem 6rem 5rem;
  }
}

.image {
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width:700px) {
    flex-direction: column;
  }
  @media (min-width:1200px) {
    flex-direction: column;
  }

  &Cad {
    display: inline-block;
    width: 90%;
    z-index: 5;
    // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.3);
    transform: translate(0, 1.5rem) scaleX(0);
    transform-origin: left;

    @media (min-width:700px) {
      width: 180%;
      transform: translate(-18rem, 2rem) scaleX(0);
    }
    @media (min-width:1200px) {
      width: 105%;
      transform: translate(0, 1.5rem) scaleX(0);
    }
    @media (min-width:1600px) {
      width: 110%;
      transform: translate(-1rem, 3rem) scaleX(0);
    }
  }
}

.text {
  position: relative;
  padding: 4.5rem 1.5rem;
  background-color: #0C3891;
  color: #fff;
  transform: scaleX(0);
  transform-origin: right;

  @media (min-width:700px) {
    padding: 4.5rem 2.5rem;
  }
  @media (min-width:1200px) {
    padding: 4.5rem 4.5rem;
    transform: translateY(-2rem) scaleX(0);
  }
  @media (min-width:1600px) {
    padding: 5.5rem 7.5rem 4.5rem;
    transform: translateY(-2.5rem) scaleX(0);
  }

  &::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #fff;

    @media (min-width:700px) {
      left: 2.5rem;
    }
    @media (min-width:1200px) {
      left: 4.5rem;
      top: 3rem;
    }
    @media (min-width:1600px) {
      top: 4rem;
      left: 7.5rem;
    }
  }
}