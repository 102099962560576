@import url(https://fonts.googleapis.com/css?family=Be+Vietnam:300,400,500,600,700,800&display=swap&subset=latin-ext);
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Be Vietnam', sans-serif;
  font-size: 1rem;
  line-height: 1.4;
  background-color: #fafafa;
  color: #0A0A0A;
  overflow-x: hidden;
}


body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #0b1e44;
}

.wrapper {
  overflow: hidden;
}

.Header_wrapper__SbKN1 {
  width: 100%;
  height: 90vh;
  background-color: #0b1e44;
  position: relative; }
  @media (min-width: 700px) {
    .Header_wrapper__SbKN1 {
      height: 50vh; } }
  @media (min-width: 1200px) {
    .Header_wrapper__SbKN1 {
      height: 80vh; } }
  .Header_wrapper__SbKN1 .Header_line__3sGv2 {
    position: absolute;
    top: 0;
    left: 15%;
    height: 100%;
    width: 3px;
    background-color: rgba(250, 250, 250, 0.2); }
  .Header_wrapper__SbKN1 .Header_line2__1ZHqJ {
    position: absolute;
    top: 15%;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.2); }

.Header_logo__2J1sE {
  width: 100%; }
  @media (min-width: 1200px) {
    .Header_logo__2J1sE {
      padding-top: 1rem; } }
  .Header_logoImg__vjRH2 {
    display: block;
    margin: 0 auto;
    width: 55%;
    padding: 1rem; }
    @media (min-width: 700px) {
      .Header_logoImg__vjRH2 {
        width: 35%; } }
    @media (min-width: 1200px) {
      .Header_logoImg__vjRH2 {
        width: 18%; } }
    @media (min-width: 1600px) {
      .Header_logoImg__vjRH2 {
        width: 13%; } }

.Header_leftDiv__6I7HM {
  height: 40vh;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 700px) {
    .Header_leftDiv__6I7HM {
      padding: 2rem 4rem; } }
  @media (min-width: 1200px) {
    .Header_leftDiv__6I7HM {
      width: 70%;
      margin: 0 auto; } }
  .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4 {
    position: relative;
    font-size: 2.2rem;
    letter-spacing: 2px;
    font-weight: 800;
    color: #fff;
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
    .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4:nth-child(3) .Header_arrow__2F3wR {
      display: inline-block;
      width: 4rem;
      -webkit-transform: translate(-1.5rem, 3rem);
              transform: translate(-1.5rem, 3rem); }
      @media (min-width: 1200px) {
        .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4:nth-child(3) .Header_arrow__2F3wR {
          width: 6rem;
          -webkit-transform: translate(-1.5rem, 4.5rem);
                  transform: translate(-1.5rem, 4.5rem); } }
      @media (min-width: 1600px) {
        .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4:nth-child(3) .Header_arrow__2F3wR {
          width: 6.5rem;
          -webkit-transform: translate(-1.5rem, 4.5rem);
                  transform: translate(-1.5rem, 4.5rem); } }
    @media (min-width: 700px) {
      .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4 {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4 {
        font-size: 3rem;
        letter-spacing: 3px; } }
    @media (min-width: 1600px) {
      .Header_leftDiv__6I7HM .Header_headerTitle__2dxP4 {
        font-size: 4rem;
        letter-spacing: 3px; } }

.Header_rightDiv__2st2r {
  position: absolute;
  right: 0;
  bottom: -15%;
  height: 50vh;
  width: 70%;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  background-image: url(../../static/media/ventilation2.be7431db.jpg);
  background-position: center;
  background-size: cover; }
  @media (min-width: 580px) and (orientation: landscape) {
    .Header_rightDiv__2st2r {
      height: 55vh;
      width: 40%;
      right: 5%;
      bottom: -20%; } }
  @media (min-width: 700px) {
    .Header_rightDiv__2st2r {
      height: 45vh;
      width: 45%;
      right: 5%;
      bottom: -30%; } }
  @media (min-width: 1200px) {
    .Header_rightDiv__2st2r {
      height: 70vh;
      width: 35%;
      right: 10%;
      bottom: -30%; } }
  @media (min-width: 1600px) {
    .Header_rightDiv__2st2r {
      height: 80vh;
      width: 40%;
      right: 10%;
      bottom: -30%; } }

.About_wrapper__1b7Dn {
  width: 100%;
  margin-top: 5rem;
  padding: 3rem 1.5rem;
  overflow: hidden;
  background: #E8ECEF; }
  @media (min-width: 700px) {
    .About_wrapper__1b7Dn {
      width: 60%;
      padding: 3rem 2.5rem; } }
  @media (min-width: 1200px) {
    .About_wrapper__1b7Dn {
      width: 50%;
      padding: 10rem 5rem 5rem; } }
  @media (min-width: 1200px) {
    .About_wrapper__1b7Dn {
      width: 50%;
      margin-top: 15rem;
      padding: 7rem; } }
  @media (min-width: 1600px) {
    .About_wrapper__1b7Dn {
      width: 55%;
      margin-top: 20rem;
      padding: 7rem; } }

.About_text__2B9TL {
  position: relative;
  padding: .5rem 0 3rem 0; }
  @media (min-width: 1200px) {
    .About_text__2B9TL {
      padding: 1rem 0; } }
  @media (min-width: 1600px) {
    .About_text__2B9TL {
      padding: 1.3rem 1rem; } }
  .About_text__2B9TL::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 70%;
    height: 3px;
    background-color: #0b1e44; }
    @media (min-width: 1600px) {
      .About_text__2B9TL::before {
        left: 1rem; } }

.About_imgs__1FNbp {
  display: grid;
  width: 100%;
  margin-bottom: 3rem; }
  @media (min-width: 700px) {
    .About_imgs__1FNbp {
      grid-template-columns: 1fr 1fr;
      padding: 1rem;
      margin-bottom: 0;
      -webkit-transform: translate(10%, -15%);
              transform: translate(10%, -15%); } }
  @media (min-width: 1200px) {
    .About_imgs__1FNbp {
      grid-template-columns: 45% 1fr;
      padding: 1rem 3rem;
      margin-bottom: -10rem;
      -webkit-transform: translate(3%, -35%);
              transform: translate(3%, -35%); } }
  @media (min-width: 1400px) {
    .About_imgs__1FNbp {
      padding: 1rem 4rem;
      -webkit-transform: translate(6%, -25%);
              transform: translate(6%, -25%); } }
  @media (min-width: 1600px) {
    .About_imgs__1FNbp {
      padding: 1rem 12rem 0rem;
      -webkit-transform: translate(10%, -30%);
              transform: translate(10%, -30%); } }
  @media (min-width: 700px) {
    .About_imgs__1FNbp .About_leftImg__36h9y {
      padding: .2rem;
      -webkit-transform: translateY(25%);
              transform: translateY(25%); } }
  @media (min-width: 1200px) {
    .About_imgs__1FNbp .About_leftImg__36h9y {
      -webkit-transform: translateY(25%);
              transform: translateY(25%); } }
  @media (min-width: 1400px) {
    .About_imgs__1FNbp .About_leftImg__36h9y {
      padding: .3rem 1.2rem .3rem .3rem; } }
  @media (min-width: 1600px) {
    .About_imgs__1FNbp .About_leftImg__36h9y {
      padding: .3rem 1.2rem .3rem .3rem;
      -webkit-transform: translateY(30%);
              transform: translateY(30%); } }
  .About_imgs__1FNbp .About_leftImg__36h9y .About_company1__19Fah {
    display: block;
    width: 100%;
    margin: .5rem 0;
    -webkit-transform: translatex(-50%) scale(0.85);
            transform: translatex(-50%) scale(0.85);
    opacity: 0; }
  @media (min-width: 700px) {
    .About_imgs__1FNbp .About_rightImg__IuFwa {
      -webkit-transform: translateY(-25%);
              transform: translateY(-25%); } }
  @media (min-width: 1200px) {
    .About_imgs__1FNbp .About_rightImg__IuFwa {
      -webkit-transform: translateY(-25%);
              transform: translateY(-25%); } }
  .About_imgs__1FNbp .About_rightImg__IuFwa .About_company3__R6LAi {
    display: block;
    width: 100%;
    margin: .5rem 0;
    -webkit-transform: translatex(50%) scale(0.85);
            transform: translatex(50%) scale(0.85);
    opacity: 0; }
    @media (min-width: 700px) {
      .About_imgs__1FNbp .About_rightImg__IuFwa .About_company3__R6LAi {
        width: 65%; } }
    @media (min-width: 1200px) {
      .About_imgs__1FNbp .About_rightImg__IuFwa .About_company3__R6LAi {
        width: 65%; } }
    @media (min-width: 1600px) {
      .About_imgs__1FNbp .About_rightImg__IuFwa .About_company3__R6LAi {
        width: 67%; } }

.Texts_text__2SJJs {
  font-size: 1.1rem;
  line-height: 1.5;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px); }
  @media (min-width: 1200px) {
    .Texts_text__2SJJs {
      font-size: 1.2rem;
      line-height: 1.6; } }
  @media (min-width: 1600px) {
    .Texts_text__2SJJs {
      font-size: 1.4rem;
      line-height: 1.8; } }

.Specjalization_wrapper__1Xolm {
  width: 100%;
  padding: 2rem 0; }
  @media (min-width: 700px) {
    .Specjalization_wrapper__1Xolm {
      display: grid;
      grid-template-columns: 1fr 55%;
      padding: 0 0 2rem; } }
  @media (min-width: 1200px) {
    .Specjalization_wrapper__1Xolm {
      padding: 2rem 3rem;
      flex-direction: column; } }
  @media (min-width: 1600px) {
    .Specjalization_wrapper__1Xolm {
      padding: 2rem 6rem 5rem; } }

.Specjalization_image__2k_AW {
  width: 100%;
  display: flex;
  justify-content: center; }
  @media (min-width: 700px) {
    .Specjalization_image__2k_AW {
      flex-direction: column; } }
  @media (min-width: 1200px) {
    .Specjalization_image__2k_AW {
      flex-direction: column; } }
  .Specjalization_imageCad__1f9aP {
    display: inline-block;
    width: 90%;
    z-index: 5;
    -webkit-transform: translate(0, 1.5rem) scaleX(0);
            transform: translate(0, 1.5rem) scaleX(0);
    -webkit-transform-origin: left;
            transform-origin: left; }
    @media (min-width: 700px) {
      .Specjalization_imageCad__1f9aP {
        width: 180%;
        -webkit-transform: translate(-18rem, 2rem) scaleX(0);
                transform: translate(-18rem, 2rem) scaleX(0); } }
    @media (min-width: 1200px) {
      .Specjalization_imageCad__1f9aP {
        width: 105%;
        -webkit-transform: translate(0, 1.5rem) scaleX(0);
                transform: translate(0, 1.5rem) scaleX(0); } }
    @media (min-width: 1600px) {
      .Specjalization_imageCad__1f9aP {
        width: 110%;
        -webkit-transform: translate(-1rem, 3rem) scaleX(0);
                transform: translate(-1rem, 3rem) scaleX(0); } }

.Specjalization_text__2yYk0 {
  position: relative;
  padding: 4.5rem 1.5rem;
  background-color: #0C3891;
  color: #fff;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right;
          transform-origin: right; }
  @media (min-width: 700px) {
    .Specjalization_text__2yYk0 {
      padding: 4.5rem 2.5rem; } }
  @media (min-width: 1200px) {
    .Specjalization_text__2yYk0 {
      padding: 4.5rem 4.5rem;
      -webkit-transform: translateY(-2rem) scaleX(0);
              transform: translateY(-2rem) scaleX(0); } }
  @media (min-width: 1600px) {
    .Specjalization_text__2yYk0 {
      padding: 5.5rem 7.5rem 4.5rem;
      -webkit-transform: translateY(-2.5rem) scaleX(0);
              transform: translateY(-2.5rem) scaleX(0); } }
  .Specjalization_text__2yYk0::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #fff; }
    @media (min-width: 700px) {
      .Specjalization_text__2yYk0::before {
        left: 2.5rem; } }
    @media (min-width: 1200px) {
      .Specjalization_text__2yYk0::before {
        left: 4.5rem;
        top: 3rem; } }
    @media (min-width: 1600px) {
      .Specjalization_text__2yYk0::before {
        top: 4rem;
        left: 7.5rem; } }

.New_wrapper__GnBQw {
  width: 100%;
  padding: 3rem 0;
  overflow: hidden; }
  @media (min-width: 1600px) {
    .New_wrapper__GnBQw {
      margin-top: 6rem; } }

.New_grayDiv__3kQUZ {
  background-color: #E8ECEF;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left; }
  @media (min-width: 700px) {
    .New_grayDiv__3kQUZ {
      display: grid;
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1200px) {
    .New_grayDiv__3kQUZ {
      grid-template-columns: 1fr 55%;
      align-items: center;
      padding: 0 3rem; } }
  @media (min-width: 1600px) {
    .New_grayDiv__3kQUZ {
      grid-template-columns: 1fr 65%;
      align-items: center;
      padding: 3rem 10rem 0; } }

.New_text__3j2_f {
  position: relative;
  padding: 4.5rem 1.5rem;
  background-color: #E8ECEF; }
  @media (min-width: 700px) {
    .New_text__3j2_f {
      padding: 4.5rem 2.5rem; } }
  @media (min-width: 1600px) {
    .New_text__3j2_f {
      padding: 2rem 1rem; } }
  .New_text__3j2_f::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #0b1e44; }
    @media (min-width: 700px) {
      .New_text__3j2_f::before {
        left: 2.5rem;
        width: 50%;
        top: 3rem; } }
    @media (min-width: 1600px) {
      .New_text__3j2_f::before {
        top: .5rem;
        left: 1rem;
        width: 85%; } }

.New_image__2wdyk {
  width: 100%;
  display: flex;
  justify-content: center; }
  .New_imageHome__1dl91 {
    display: inline-block;
    width: 90%;
    z-index: 5;
    -webkit-transform: translate(0, -2rem) scaleY(0);
            transform: translate(0, -2rem) scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top; }
    @media (min-width: 700px) {
      .New_imageHome__1dl91 {
        -webkit-transform: translate(0, 6rem) scaleY(0);
                transform: translate(0, 6rem) scaleY(0); } }
    @media (min-width: 1200px) {
      .New_imageHome__1dl91 {
        width: 95%; } }
    @media (min-width: 1600px) {
      .New_imageHome__1dl91 {
        width: 75%; } }

.Footer_wrapper__3T6CO {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #0B1E45;
  padding: 1rem;
  color: #fff; }
  @media (min-width: 1200px) {
    .Footer_wrapper__3T6CO {
      padding: 3rem 1rem 1rem;
      margin-top: 7rem; } }
  .Footer_wrapper__3T6CO .Footer_line__FkPUZ {
    position: absolute;
    top: 0;
    left: 5%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    height: 100%;
    width: 3px;
    background-color: rgba(250, 250, 250, 0.2); }
    @media (min-width: 700px) {
      .Footer_wrapper__3T6CO .Footer_line__FkPUZ {
        left: 10%; } }
    @media (min-width: 1600px) {
      .Footer_wrapper__3T6CO .Footer_line__FkPUZ {
        left: 20%; } }
  .Footer_wrapper__3T6CO .Footer_line2__30QQU {
    position: absolute;
    bottom: 16%;
    left: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    height: 3px;
    width: 100%;
    background-color: rgba(250, 250, 250, 0.2); }

.Footer_box__d9nBP {
  width: 100%;
  padding: 1rem 1rem 0; }
  @media (min-width: 700px) {
    .Footer_box__d9nBP {
      width: 80%;
      margin: 0 auto; } }
  @media (min-width: 1600px) {
    .Footer_box__d9nBP {
      width: 60%;
      margin: 0 auto; } }
  .Footer_boxContact__l5r73 {
    padding: 1rem 0; }
    @media (min-width: 1200px) {
      .Footer_boxContact__l5r73 .Footer_title__3XHHF {
        font-size: 1.5rem; } }
    .Footer_boxContact__l5r73 .Footer_email__3zzan {
      position: relative;
      display: inline-block;
      margin-top: .5rem;
      text-decoration: none;
      color: #fff;
      font-style: italic;
      font-weight: 300;
      letter-spacing: 1px;
      z-index: 5; }
      .Footer_boxContact__l5r73 .Footer_email__3zzan::before {
        position: absolute;
        content: '';
        z-index: -1;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 4px;
        background-color: rgba(145, 100, 0, 0.4);
        -webkit-transition: .4s;
        transition: .4s; }
      .Footer_boxContact__l5r73 .Footer_email__3zzan:hover::before {
        height: 18px; }
  .Footer_boxAddress__2BLDU {
    display: grid;
    grid-template-columns: 65% 1fr;
    align-items: flex-end;
    padding: 1rem 0; }
    @media (min-width: 700px) {
      .Footer_boxAddress__2BLDU {
        width: 60%; } }
    @media (min-width: 1200px) {
      .Footer_boxAddress__2BLDU {
        width: 35%; } }
    @media (min-width: 1600px) {
      .Footer_boxAddress__2BLDU {
        padding: 1rem 0 2rem; } }
    .Footer_boxAddressLeft__3IjHj .Footer_addressText__3Raht {
      margin: .4rem 0; }
    .Footer_boxAddressRight__19f9g {
      display: flex;
      justify-content: center; }
      .Footer_boxAddressRight__19f9g .Footer_howto__1taha {
        display: block;
        width: 7rem;
        padding: .4rem; }
  .Footer_boxCopyright__PjaT2 {
    display: flex;
    justify-content: space-between;
    padding: .5rem 0; }
    @media (min-width: 700px) {
      .Footer_boxCopyright__PjaT2 {
        justify-content: space-around; } }
    .Footer_boxCopyright__PjaT2 .Footer_copyright__1cj4w {
      font-size: .8rem;
      font-weight: 300; }
    .Footer_boxCopyright__PjaT2 .Footer_blusoft__11Uhx {
      font-size: .8rem;
      font-weight: 300;
      text-decoration: none;
      color: #fff; }

.Leak_wrapper__1x54K {
  width: 100%;
  padding: 2rem 0; }
  @media (min-width: 700px) {
    .Leak_wrapper__1x54K {
      display: grid;
      grid-template-columns: 55% 1fr; } }
  @media (min-width: 1200px) {
    .Leak_wrapper__1x54K {
      padding: 2rem 4rem 2rem 6rem;
      margin-bottom: 7rem; } }
  @media (min-width: 1600px) {
    .Leak_wrapper__1x54K {
      width: 85%;
      margin: 0 auto 9rem;
      padding-bottom: 4rem; } }

.Leak_text__sw0VL {
  position: relative;
  padding: 4.5rem 1.5rem;
  background-color: #0B1E45;
  color: #fff;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top;
          transform-origin: top; }
  @media (min-width: 700px) {
    .Leak_text__sw0VL {
      padding: 4.5rem 2.5rem; } }
  @media (min-width: 1200px) {
    .Leak_text__sw0VL {
      display: flex;
      align-items: center;
      padding: 4.5rem 4.5rem 3rem; } }
  @media (min-width: 1600px) {
    .Leak_text__sw0VL {
      padding: 4.5rem 8rem 3rem; } }
  .Leak_text__sw0VL::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #fff; }
    @media (min-width: 700px) {
      .Leak_text__sw0VL::before {
        left: 2.5rem; } }
    @media (min-width: 1200px) {
      .Leak_text__sw0VL::before {
        left: 4.5rem;
        top: 7rem; } }
    @media (min-width: 1600px) {
      .Leak_text__sw0VL::before {
        top: 5rem;
        width: 50%;
        left: 8rem; } }

.Leak_image__3Bbmq {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .Leak_imageLeak__1LJzx {
    display: inline-block;
    width: 85%;
    z-index: 5;
    -webkit-transform: translate(0, -1.5rem) scaleY(0);
            transform: translate(0, -1.5rem) scaleY(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
    @media (min-width: 700px) {
      .Leak_imageLeak__1LJzx {
        width: 95%;
        -webkit-transform: translate(-2.5rem, 4.5rem) scaleY(0);
                transform: translate(-2.5rem, 4.5rem) scaleY(0); } }
    @media (min-width: 1200px) {
      .Leak_imageLeak__1LJzx {
        width: 85%;
        -webkit-transform: translate(-7.5rem, 6.5rem) scaleY(0);
                transform: translate(-7.5rem, 6.5rem) scaleY(0); } }
    @media (min-width: 1600px) {
      .Leak_imageLeak__1LJzx {
        width: 75%;
        -webkit-transform: translate(-16rem, 7.5rem) scaleY(0);
                transform: translate(-16rem, 7.5rem) scaleY(0); } }

.FatArrow_wrapper__1lmf0 {
  width: 100%;
  display: flex;
  justify-content: center; }
  .FatArrow_wrapper__1lmf0 .FatArrow_fatArrow__2fz39 {
    width: 7rem;
    -webkit-transform: rotate(-30deg) scale(1);
            transform: rotate(-30deg) scale(1);
    opacity: 1; }
    @media (min-width: 700px) {
      .FatArrow_wrapper__1lmf0 .FatArrow_fatArrow__2fz39 {
        -webkit-transform: rotate(0);
                transform: rotate(0); } }
    @media (min-width: 700px) {
      .FatArrow_wrapper__1lmf0 .FatArrow_fatArrow__2fz39 {
        width: 9rem;
        margin-top: -5rem; } }
    @media (min-width: 1600px) {
      .FatArrow_wrapper__1lmf0 .FatArrow_fatArrow__2fz39 {
        width: 12rem;
        margin-top: -6rem;
        z-index: 5; } }

.Regeneration_wrapper__1M35L {
  width: 100%;
  padding: 1rem 0;
  overflow: hidden; }
  @media (min-width: 700px) {
    .Regeneration_wrapper__1M35L {
      margin-bottom: 3rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center; } }
  @media (min-width: 1200px) {
    .Regeneration_wrapper__1M35L {
      grid-template-columns: 40% 1fr; } }
  @media (min-width: 1600px) {
    .Regeneration_wrapper__1M35L {
      margin-bottom: 6rem; } }

.Regeneration_rekuperacjaImg__2wHyX {
  display: block;
  width: 100%;
  margin: 0 auto;
  -webkit-transform: translate(0, -2rem) scaleY(0);
          transform: translate(0, -2rem) scaleY(0);
  -webkit-transform-origin: bottom;
          transform-origin: bottom; }
  @media (min-width: 700px) {
    .Regeneration_rekuperacjaImg__2wHyX {
      width: 90%; } }
  @media (min-width: 1200px) {
    .Regeneration_rekuperacjaImg__2wHyX {
      width: 80%; } }
  @media (min-width: 1600px) {
    .Regeneration_rekuperacjaImg__2wHyX {
      width: 70%; } }

.Regeneration_text__24R3Q {
  position: relative;
  padding: 4.5rem 1.5rem; }
  @media (min-width: 700px) {
    .Regeneration_text__24R3Q {
      padding: 4.5rem 2.5rem; } }
  @media (min-width: 1600px) {
    .Regeneration_text__24R3Q {
      padding: 2rem 1rem;
      margin-right: 14rem; } }
  .Regeneration_text__24R3Q::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #0b1e44; }
    @media (min-width: 700px) {
      .Regeneration_text__24R3Q::before {
        left: 2.5rem;
        width: 50%;
        top: 3rem; } }
    @media (min-width: 1600px) {
      .Regeneration_text__24R3Q::before {
        top: .5rem;
        left: 1rem;
        width: 85%; } }

