.text {
  font-size: 1.1rem;
  line-height: 1.5;
  opacity: 0;
  transform: translateY(40px);

  @media (min-width:1200px) {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  @media (min-width:1600px) {
    font-size: 1.4rem;
    line-height: 1.8;
  }
}