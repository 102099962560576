.wrapper {
  width: 100%;
  padding: 3rem 0;
  overflow: hidden;

  @media (min-width:700px) {
    // margin-bottom: 3rem;
  }
  @media (min-width:1200px) {
    // margin: 3rem 0;
  }
  @media (min-width:1600px) {
    margin-top: 6rem;
  }
}

.grayDiv {
  background-color: #E8ECEF;
  transform: scaleX(0);
  transform-origin: left;

  @media (min-width:700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width:1200px) {
    grid-template-columns: 1fr 55%;
    align-items: center;
    padding: 0 3rem;
  }
  @media (min-width:1600px) {
    grid-template-columns: 1fr 65%;
    align-items: center;
    padding: 3rem 10rem 0;
  }
}

.text {
  position: relative;
  padding: 4.5rem 1.5rem;
  background-color: #E8ECEF;

  @media (min-width:700px) {
    padding: 4.5rem 2.5rem;
  }
  @media (min-width:1200px) {
    
  }
  @media (min-width:1600px) {
    padding: 2rem 1rem;
  }

  &::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #0b1e44;

    @media (min-width:700px) {
      left: 2.5rem;
      width: 50%;
      top: 3rem;
    }
    @media (min-width:1600px) {
      top: .5rem;
      left: 1rem;
      width: 85%;
    }
  }
}

.image {
  width: 100%;
  display: flex;
  justify-content: center;
  
  @media (min-width:700px) {

  }
  @media (min-width:1200px) {

  }

  &Home {
    display: inline-block;
    width: 90%;
    z-index: 5;
    // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.3);
    transform: translate(0, -2rem) scaleY(0);
    transform-origin: top;

    @media (min-width:700px) {
      transform: translate(0, 6rem) scaleY(0);
    }
    @media (min-width:1200px) {
      width: 95%;
    }
    @media (min-width:1600px) {
      width: 75%;
    }
  }
}
