.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .fatArrow {
    width: 7rem;
    transform: rotate(-30deg) scale(1);
    opacity: 1;

    @media (min-width:700px) {
      transform: rotate(0);
    }
    @media (min-width:700px) {
      width: 9rem;
      margin-top: -5rem;
    }
    @media (min-width:1600px) {
      width: 12rem;
      margin-top: -6rem;
      z-index: 5;
    }
  }
}