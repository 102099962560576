.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #0B1E45;
  padding: 1rem;
  color: #fff;

  @media (min-width:1200px) {
    padding: 3rem 1rem 1rem;
    margin-top: 7rem;
  }

  .line {
    position: absolute;
    top: 0;
    left: 5%;
    transform: translateY(-100%);
    height: 100%;
    width: 3px;
    background-color: rgba(250,250,250,0.2);

    @media (min-width:700px) {
      left: 10%;
    }
    @media (min-width:1600px) {
      left: 20%;
    }
  }
  .line2 {
    position: absolute;
    bottom: 16%;
    left: 0;
    transform: translateX(-100%);
    height: 3px;
    width: 100%;
    background-color: rgba(250,250,250,0.2);
  }
}

.box {
  width: 100%;
  padding: 1rem 1rem 0;

  @media (min-width:700px) {
    width: 80%;
    margin: 0 auto;
  }
  @media (min-width:1600px) {
    width: 60%;
    margin: 0 auto;
  }

  &Contact {
    padding: 1rem 0;

    .title {

      @media (min-width:1200px) {
        font-size: 1.5rem;
      }
    }

    .email {
      position: relative;
      display: inline-block;
      margin-top: .5rem;
      text-decoration: none;
      color: #fff;
      font-style: italic;
      font-weight: 300;
      letter-spacing: 1px;
      z-index: 5;

      &::before {
        position: absolute;
        content: '';
        z-index: -1;
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 4px;
        background-color: rgba(145,100,0, .4);
        transition: .4s;
      }

      &:hover::before {
        height: 18px;
      }
    }
  }

  &Address {
    display: grid;
    grid-template-columns: 65% 1fr;
    align-items: flex-end;
    padding: 1rem 0;

    @media (min-width:700px) {
      width: 60%;
    }
    @media (min-width:1200px) {
      width: 35%;
    }
    @media (min-width:1600px) {
      padding: 1rem 0 2rem;
    }

    &Left {
      
      .addressText {
        margin: .4rem 0;
      }
    }

    &Right {
      display: flex;
      justify-content: center;

      .howto {
        display: block;
        width: 7rem;
        padding: .4rem;
      }
    }
    
  }

  &Copyright {
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;

    @media (min-width:700px) {
      justify-content: space-around;
    }

    .copyright {
      font-size: .8rem;
      font-weight: 300;
    }

    .blusoft {
      font-size: .8rem;
      font-weight: 300;
      text-decoration: none;
      color: #fff;
    }
  }
}

