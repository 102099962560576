.wrapper {
  width: 100%;
  margin-top: 5rem;
  padding: 3rem 1.5rem;
  overflow: hidden;
  background: #E8ECEF;

  @media (min-width:700px) {
    width: 60%;
    padding: 3rem 2.5rem;
  }
  @media (min-width:1200px) {
    width: 50%;
    padding: 10rem 5rem 5rem;
  }
  @media (min-width:1200px) {
    width: 50%;
    margin-top: 15rem;
    padding: 7rem;
  }
  @media (min-width:1600px) {
    width: 55%;
    margin-top: 20rem;
    padding: 7rem;
  }
}

.text {
  position: relative;
  padding: .5rem 0 3rem 0;

  @media (min-width:1200px) {
    padding: 1rem 0;
  }
  @media (min-width:1600px) {
    padding: 1.3rem 1rem;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 70%;
    height: 3px;
    background-color: #0b1e44;

    @media (min-width:1600px) {
      left: 1rem;
    }
  }
}

.imgs {
  display: grid;
  width: 100%;
  margin-bottom: 3rem;

  @media (min-width:700px) {
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    margin-bottom: 0;
    transform: translate(10%, -15%);
  }
  @media (min-width:1200px) {
    grid-template-columns: 45% 1fr;
    padding: 1rem 3rem;
    margin-bottom: -10rem;
    transform: translate(3%, -35%);
  }
  @media (min-width:1400px) {
    padding: 1rem 4rem;
    transform: translate(6%, -25%);
  }
  @media (min-width:1600px) {
    padding: 1rem 12rem 0rem;
    transform: translate(10%, -30%);
  }

  .leftImg {

    @media (min-width:700px) {
      padding: .2rem;
      transform: translateY(25%);
    }
    @media (min-width:1200px) {
      transform: translateY(25%);
    }
    @media (min-width:1400px) {
      padding: .3rem 1.2rem .3rem .3rem;
    }
    @media (min-width:1600px) {
      padding: .3rem 1.2rem .3rem .3rem;
      transform: translateY(30%);
    }

    .company1 {
      display: block;
      width: 100%;
      margin: .5rem 0;
      // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.2);
      transform: translatex(-50%) scale(.85);
      opacity: 0;
    }
  }
  
  .rightImg {

    @media (min-width:700px) {
      transform: translateY(-25%);
    }

    @media (min-width:1200px) {
      transform: translateY(-25%);
    }

    .company3 {
      display: block;
      width: 100%;
      margin: .5rem 0;
      // box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.4);
      transform: translatex(50%) scale(.85);
      opacity: 0;

      @media (min-width:700px) {
        width: 65%;
      }
      @media (min-width:1200px) {
        width: 65%;
      }
      @media (min-width:1600px) {
        width: 67%;
      }
    }
  }
  
}