.wrapper {
  width: 100%;
  padding: 2rem 0;

  @media (min-width:700px) {
    display: grid;
    grid-template-columns: 55% 1fr;
  }
  @media (min-width:1200px) {
    padding: 2rem 4rem 2rem 6rem;
    margin-bottom: 7rem;
  }
  @media (min-width:1600px) {
    width: 85%;
    margin: 0 auto 9rem;
    padding-bottom: 4rem;
  }
}

.text {
  position: relative;
  padding: 4.5rem 1.5rem;
  background-color: #0B1E45;
  color: #fff;
  transform: scaleY(0);
  transform-origin: top;

  @media (min-width:700px) {
    padding: 4.5rem 2.5rem;
  }
  @media (min-width:1200px) {
    display: flex;
    align-items: center;
    padding: 4.5rem 4.5rem 3rem;
  }
  @media (min-width:1600px) {
    padding: 4.5rem 8rem 3rem;
  }

  &::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #fff;

    @media (min-width:700px) {
      left: 2.5rem;
    }
    @media (min-width:1200px) {
      left: 4.5rem;
      top: 7rem;
    }
    @media (min-width:1600px) {
      top: 5rem;
      width: 50%;
      left: 8rem;
    }
  }
}

.image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &Leak {
    display: inline-block;
    width: 85%;
    z-index: 5;
    // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.3);
    transform: translate(0, -1.5rem) scaleY(0);
    transform-origin: bottom;

    @media (min-width:700px) {
      width: 95%;
      transform: translate(-2.5rem, 4.5rem) scaleY(0);
    }
    @media (min-width:1200px) {
      width: 85%;
      transform: translate(-7.5rem, 6.5rem) scaleY(0);
    }
    @media (min-width:1600px) {
      width: 75%;
      transform: translate(-16rem, 7.5rem) scaleY(0);
    }
  }
}