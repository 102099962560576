@import url('https://fonts.googleapis.com/css?family=Be+Vietnam:300,400,500,600,700,800&display=swap&subset=latin-ext');

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Be Vietnam', sans-serif;
  font-size: 1rem;
  line-height: 1.4;
  background-color: #fafafa;
  color: #0A0A0A;
  overflow-x: hidden;
}


body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #0b1e44;
}

.wrapper {
  overflow: hidden;
}
