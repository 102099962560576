.wrapper {
  width: 100%;
  padding: 1rem 0;
  overflow: hidden;

  @media (min-width:700px) {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }
  @media (min-width:1200px) {
    grid-template-columns: 40% 1fr;
  }
  @media (min-width:1600px) {
     margin-bottom: 6rem;
  }
}

.rekuperacja {

  &Img {
    display: block;
    width: 100%;
    margin: 0 auto;
    transform: translate(0, -2rem) scaleY(0);
    transform-origin: bottom;
    // box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);

    @media (min-width:700px) {
      width: 90%;
    }
    @media (min-width:1200px) {
      width: 80%;
    }
    @media (min-width:1600px) {
      width: 70%;
    }
  }
}

.text {
  position: relative;
  padding: 4.5rem 1.5rem;

  @media (min-width:700px) {
    padding: 4.5rem 2.5rem;
  }
  @media (min-width:1200px) {
    
  }
  @media (min-width:1600px) {
    padding: 2rem 1rem;
    margin-right: 14rem;
  }

  &::before {
    position: absolute;
    content: '';
    top: 4rem;
    left: 1.5rem;
    width: 70%;
    height: 3px;
    background-color: #0b1e44;

    @media (min-width:700px) {
      left: 2.5rem;
      width: 50%;
      top: 3rem;
    }
    @media (min-width:1600px) {
      top: .5rem;
      left: 1rem;
      width: 85%;
    }
  }
}